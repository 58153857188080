.mainContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: calc(100vh - 180px);
}

.container {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.title {
  flex: 1;
  align-items: center;
  display: flex;
  font-style: italic;
  letter-spacing: 20px;
  font-size: 40px;
  font-weight: 800;
  padding: 40px;
  animation: typing 8s steps(60, end);

  transition: color 1s, font-size 0.5s;
}

.contact {
  letter-spacing: 5px;
  font-size: 12px;
  padding: 40px 40px;
}

.contact a {
  color: white;
} 

.formContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 10px;
  width: 300px;
  box-sizing: border-box;
}

.form button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 28px;
  border-radius: 9px;
  border-width: 0px;
  gap: 20px;
  width: 170px;
  color: white;
  background-color: #385178;
}

.form textarea, .form input[type="text"], .form input[type="email"] {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  width: 100%;
  border-radius: 9px;
  border-width: 0px;
  background-color: transparent;
  border: 3px solid white; 
  color: white;
  gap: 20px;
}

.form textarea::placeholder,
  .form input[type="text"]::placeholder,
  .form input[type="email"]::placeholder {
  color: white;
  opacity: 0.7;
}

.form textarea::-ms-input-placeholder,
.form input[type="text"]::-ms-input-placeholder,
.form input[type="email"]::-ms-input-placeholder {
  color: white;
  opacity: 0.7;
}

.form textarea {
  height: 100px;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.submitDiv {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 600px) {
  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .title {
    font-size: 18px;
    letter-spacing: 17px;
    padding: 0 20px;
  }
}


@media (min-width: 600px) and (max-width: 900px) {
  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .title {
    font-size: 30px;
    letter-spacing: 20px;
    padding: 0 40px;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {

  .title {
    font-size: 25px;
    letter-spacing: 20px;
    padding: 0 40px;
  }
}

